import React, {ReactElement, useEffect, useState} from 'react';
import {useForm, useFormContext, FormProvider, FieldValues, UseFormReturn} from "react-hook-form";
import {useNavigate, useParams, useRouteLoaderData} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useUppy} from '@uppy/react';
import Uppy from '@uppy/core'
import {ThumbnailGenerator} from "uppy";
import Logo from "./Logo";
import Infos from "./Infos";
import Sent from "./Sent";
import Renderer from "./Renderer";
import PanelContent from "./PanelContent";
import {ReactComponent as Back} from '../assets/svg/arrow-back.svg';
import Spot from "./Spot";
import {ROUTES} from "../constants/routes";
import Loading from "./Loading";

export type SimulationFormData = {
  spot: string;
  logo: any;
  email: string;
  agreeLegals: boolean;
  agreeContact: boolean;
};

const Simulation = () => {
  const {t} = useTranslation();
  const {panorama, spotID, logoID} = useParams();
  const [step, setStep] = useState<'spot' | 'logo' | 'infos' | 'sent'>('spot');
  
  const [capture, setCapture] = useState(false);
  const [captureData, setCaptureData] = useState<string | null>(null);
  const history = useNavigate();
  const methods = useForm<SimulationFormData>({
    defaultValues: {
      logo: ''
    }
  });
  
  const [loading, setLoading] = useState<boolean>(false);
  
  const onSubmit = (data: any) => {
    setLoading(true);
    setCapture(true);
  };
  
  const data: any = useRouteLoaderData("root");
  
  const watchLogo = methods.watch("logo", null);
  
  useEffect(() => {
    if (captureData) {
      
      const upload = async () => {
        
        const values = methods.getValues();
        
        const url = process.env.NODE_ENV === 'development' ? 'https://agence-mpb-2024.beontest.com/simulation' : 'https://agence-mpb-2024.beontest.com/simulation';
        
        const raw = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            image: captureData,
            email: values.email,
            agreeLegals: values.agreeLegals,
            agreeContact: values.agreeContact
          }),
          headers: {
            'Accept': 'application/json'
          },
        });
        
        const json = await raw.json();
        setStep('sent');
        setLoading(false);
      }
      
      upload();
      
    }
  }, [captureData])
  
  useEffect(() => {
    if (spotID && data) {
      setStep('logo');
    }
  }, [spotID]);
  
  const uppy = useUppy(() => {
    return new Uppy({
      debug: false,
      autoProceed: false,
      restrictions: {
        maxFileSize: null,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['.png', '.svg'],
      },
    }).use(ThumbnailGenerator, {
      thumbnailWidth: 512,
      waitForThumbnailsBeforeUpload: false,
    })
  });
  
  const onBack = () => {
    switch (step) {
      case 'spot' :
      case 'sent' :
        history(`/${panorama}/${ROUTES.INTRO}`);
        break;
      case 'infos' :
        setStep('logo');
        break;
      case 'logo' :
        setStep('spot');
        break;
    }
  }
  
  const onNext = () => {
    switch (step) {
      case 'spot' :
        setStep('logo');
        break;
      case 'logo' :
        setStep('infos');
        break;
    }
  }
  
  const onCapture = async (data: string) => {
    setCaptureData(data);
    setCapture(false);
  }
  
  const spot = data.placeholders.find((placeholder: any) => placeholder.id === spotID);
  let spotPosition: any = null;
  
  if (spot && spot.rect) {
    spotPosition = spot.rect;
  }
  
  if (watchLogo && spotPosition) {
    spotPosition.id = spotID;
    spotPosition.logo = watchLogo;
  }
  
  return (
    <PanelContent>
      <div>
        <h1 className={'text-primary font-extrabold font-sans flex'}>
          <button onClick={onBack} className={'pr-6 pt-2 flex'}>
            <Back className={'block w-8 h-8'}/>
          </button>
          <span>{t('simulation.title')}</span>
        </h1>
      </div>
      <p className={'mb-4 line'}>{t('simulation.description')}</p>
      <FormProvider {...methods}>
        {capture && <Renderer data={data} position={spotPosition} captureReady={onCapture}/>}
        <form className={'relative flex flex-col min-w-[200px] flex-grow flex '}
              onSubmit={methods.handleSubmit(onSubmit)}>
          {step === 'spot' && <Spot next={onNext}/>}
          {step === 'logo' && <Logo next={onNext} watch={methods.watch} back={onBack} uppy={uppy}/>}
          {step === 'infos' && <Infos back={onBack}/>}
          {step === 'sent' && <Sent/>}
          {loading && <Loading/>}
          <div className={'flex gap-4 py-10 items-center justify-center'}>
            <button type={"button"} onClick={() => setStep('spot')}
                    className={`rounded-full w-3.5 h-3.5 ${step === 'spot' ? 'bg-primary' : 'bg-gray-light'}`}/>
            <button type={"button"} onClick={() => setStep('logo')}
                    className={`rounded-full w-3.5 h-3.5 ${step === 'logo' ? 'bg-primary' : 'bg-gray-light'}  ${(step === 'logo' || step === 'infos') ? 'pointer-events-auto' : 'pointer-events-none'}`}/>
            <button type={"button"} onClick={() => setStep('infos')}
                    className={`rounded-full w-3.5 h-3.5 ${step === 'infos' ? 'bg-primary' : 'bg-gray-light'}  ${step === 'infos' ? 'pointer-events-auto' : 'pointer-events-none'}`}/>
          </div>
        </form>
      </FormProvider>
    </PanelContent>
  );
}

export default Simulation;

interface ConnectFormProps<TFieldValues extends FieldValues> {
  children(children: UseFormReturn<TFieldValues>): ReactElement;
}

export const ConnectForm = <TFieldValues extends FieldValues>({children}: ConnectFormProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>();
  return children({...methods});
};
